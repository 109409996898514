import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span style={{
      "fontWeight": "400"
    }}>
  We’re excited to launch an integration with Drift to empower companies to
  build and refine the most intelligent and effective customer service virtual
  assistants. Drift is a conversational marketing platform that helps you build
  a digital, personal assistant for your website. This strategic partnership
  enables companies to optimize conversations to increase conversions by
  analyzing customer behavior and queries.{" "}
    </span>
    <blockquote>
      <p parentName="blockquote">{`“Drift is excited to partner with Dashbot to provide our customers with advanced conversational analytics to help optimize both human and chatbot conversations.”`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Jared Fuller, Sr. Director of Partnerships at Drift`}</strong></p>
    </blockquote>
    <span style={{
      "fontWeight": "400"
    }}>
  Creating a conversational experience is unlike any other product because of
  the complexity and variety in language. Currently, people are manually
  identifying and grouping similar messages which is tedious and not scalable.
  Dashbot’s [Phrase
  Clustering](https://www.dashbot.io/features/phrase-clustering/) unravels and
  organizes these conversations in an easy-to-understand format that you can
  export to train your customer service chatbot and empower your customer
  service agents.
    </span>
    <blockquote>
      <p parentName="blockquote">{`“We’re very excited to provide our advanced conversational analytics, like phrase clustering and audience segmentation, to conversations handled both by human and automated agents. A huge amount of value can be discovered by analyzing these rich corpuses of conversations.”`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Dennis Yang, CoFounder `}{`&`}{` CPO of Dashbot`}</strong></p>
    </blockquote>
    <span style={{
      "fontWeight": "400"
    }}>
  By integrating Dashbot with your Drift chatbot, you can track and manage all
  of your conversational marketing, by analyzing:
    </span>
    <ul>
      <li parentName="ul">
        <span style={{
          "fontWeight": "400"
        }}>
  NLP Optimization with [Phrase
  Clustering](https://www.dashbot.io/features/phrase-clustering/)
        </span>
      </li>
      <li parentName="ul">
        <span style={{
          "fontWeight": "400"
        }}>Top Customer Queries</span>
      </li>
      <li parentName="ul">
        <span style={{
          "fontWeight": "400"
        }}>Conversation Paths</span>
      </li>
      <li parentName="ul">{`Audience Segmentation`}</li>
      <li parentName="ul">
        <span style={{
          "fontWeight": "400"
        }}>User &amp; Session Counts</span>
      </li>
      <li parentName="ul">
        <span style={{
          "fontWeight": "400"
        }}>Sentiment Analysis</span>
      </li>
      <li parentName="ul">{`And more!`}</li>
    </ul>
    <span style={{
      "fontWeight": "400"
    }}>
  Integrate Dashbot analytics into Drift:{" "}
  <a href="https://www.dashbot.io/docs/drift" />
    </span>
    <h2><span style={{
        "fontWeight": "400"
      }}>{`About Dashbot`}</span></h2>
    <span style={{
      "fontWeight": "400"
    }}>
  Dashbot is a conversational analytics platform that enables developers and
  brands to increase engagement, acquisition, and monetization through
  actionable data and tools. In addition to traditional analytics like
  engagement and retention, we provide bot and voice specific metrics including
  sentiment analysis, conversational analytics, and full transcripts. We also
  have tools to take action on the data, like our live-person takeover,
  broadcast messaging, and goal funnels.
    </span>
    <span style={{
      "fontWeight": "400"
    }}>
  Learn more about Dashbot’s analytics and tools and sign up for free:
  [www.dashbot.io](https://www.dashbot.io/).
    </span>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>
    <h2><span style={{
        "fontWeight": "400"
      }}>{`About Drift`}</span></h2>
    <span style={{
      "fontWeight": "400"
    }}>
  Drift provides a buying experience for potential customers, generate more
  qualified leads, and dramatically accelerate the sales cycle. Drift is a
  conversation-driven marketing and sales platform. With its quickly evolving
  set of tools and playbooks, Drift unlocks the insights buried in conversations
  to accelerate business success. Customers use Drift to provide a modern buying
  experience for potential customers, generate more qualified leads, and
  dramatically accelerate the sales cycle.
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      